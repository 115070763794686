import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// material-ui
import { Button, Grid, InputLabel, Slider, Stack, TextField, Typography } from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NumericFormat } from 'react-number-format';

// project-imports
import AnimateButton from 'components/@extended/AnimateButton';
import MainCard from 'components/MainCard';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import ProductSelect from 'components/select-boxes/ProductSelect';
import { format } from 'date-fns';
import { findMarkForValueUrgencyCell, marksUrgency } from 'components/data/UrgencyCell';
import DateSelect from 'components/select-boxes/DateSelect';

const validationSchema = yup.object({
  product: yup.object().required('Ürün seçiniz'),
  amount: yup.number().typeError('Geçerli bir miktar giriniz').min(0.00001, "Miktar 0'dan büyük olmalıdır").required('Miktar giriniz'),
  urgency: yup.number().typeError('Geçerli bir aciliyet seçiniz'),
  deadline: yup.date().typeError('Geçerli bir tarih seçiniz'),
  brand: yup.string().max(56, 'Marka adı en fazla 56 karakter olabilir'),
  model: yup.string().max(56, 'Model adı en fazla 56 karakter olabilir'),
  note: yup.string().max(255, 'Not en fazla 255 karakter olabilir')
});

const validationSchemaForQuotas = yup.object({
  product: yup.object().required('Ürün seçiniz')
});

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      allowLeadingZeros={false}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
};

// ==============================|| VALIDATION WIZARD - ADDRESS  ||============================== //

const AddProduct = ({ onlyQuotas, quotationProject, rowToUpdate, setVisible, handleAddProduct, setErrorIndex }) => {
  const formik = useFormik({
    initialValues: {
      project: quotationProject || null,
      product: rowToUpdate?.product || null,
      amount: rowToUpdate?.amount || '',
      urgency: rowToUpdate?.urgency || '',
      deadline: rowToUpdate ? new Date(rowToUpdate.deadline) : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      brand: rowToUpdate?.brand || '',
      model: rowToUpdate?.model || '',
      note: rowToUpdate?.note || ''
    },
    validationSchema: onlyQuotas == true ? validationSchemaForQuotas : validationSchema,
    onSubmit: (values) => {
      setErrorIndex && setErrorIndex(0);
      handleAddProduct({
        project: values.project,
        product: values.product,
        amount: values.amount,
        urgency: values.urgency,
        deadline: format(values.deadline, 'yyyy-MM-dd'),
        brand: values.brand,
        model: values.model,
        note: values.note
      });
      setVisible(false);
    }
  });
  console.log('formik.values', formik.values);
  const [creationVisible, setCreationVisible] = useState(false);
  console.log('rowToUpdate', rowToUpdate);
  console.log('onlyQuotas', onlyQuotas);
  console.log('formik.errors', formik.errors);

  useEffect(() => {
    if (onlyQuotas) {
      formik.handleSubmit();
    }
  }, [formik.values.product]);

  return (
    <MainCard title={rowToUpdate != undefined ? 'Talep Edilen Ürün Güncelle' : 'Talep Edilen Ürün'}>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <ProductSelect
                selectedProduct={formik.values.product}
                setSelectedProduct={(value) => {
                  formik.setFieldValue('product', value);
                }}
                creationVisible={creationVisible}
                setCreationVisible={setCreationVisible}
                formik={formik}
                onChange={() => {
                  formik.setFieldTouched('product', true);
                }}
              />
              {!formik?.values?.product && (
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                  <Typography variant="text" color="primary">
                    Aradığınız ürün yok mu?
                  </Typography>
                  <Button variant="text" color="primary" onClick={() => setCreationVisible(true)}>
                    Ürün Tanımlayın
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>

          {formik.values.product && (
            <>
              <Grid item xs={12} lg={4}>
                <Stack spacing={1}>
                  <InputLabel>Ürün Adı</InputLabel>
                  <Typography variant="h5">{formik.values.product?.name || ''}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={1}>
                  <InputLabel>Ölçü Birimi</InputLabel>
                  <Typography variant="h5">{formik.values.product?.unit || ''}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={1}>
                  <InputLabel>Tip</InputLabel>
                  <Typography variant="h5">{formik.values.product?.type || ''}</Typography>
                </Stack>
              </Grid>
            </>
          )}

          {!onlyQuotas && (
            <>
              <Grid item xs={12} lg={12}>
                <Stack spacing={1}>
                  <InputLabel>Miktar</InputLabel>
                  <TextField
                    id="amount"
                    name="amount"
                    placeholder=""
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                    helperText={formik.touched.amount && formik.errors.amount}
                    fullWidth
                    type="text"
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel>Marka</InputLabel>
                  <TextField
                    id="brand"
                    name="brand"
                    placeholder="Tercih ettiğiniz markayı belirtebilirsiniz."
                    value={formik.values.brand}
                    onChange={formik.handleChange}
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                    fullWidth
                    type="text"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel>Model</InputLabel>
                  <TextField
                    id="model"
                    name="model"
                    placeholder="Model bilgisini belirtebilirsiniz."
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                    fullWidth
                    type="text"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel>Gereklilik Tarihi</InputLabel>

                  <Stack spacing={3}>
                    <DateSelect value={formik.values.deadline} onChange={(date) => formik.setFieldValue('deadline', date)} />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Stack spacing={1} style={{ paddingInline: '10px' }}>
                  <InputLabel>Aciliyet</InputLabel>

                  <Slider
                    aria-label="Restricted values"
                    defaultValue={30}
                    step={1}
                    marks={marksUrgency}
                    color={findMarkForValueUrgencyCell(formik.values.urgency).color}
                    valueLabelDisplay="off"
                    value={formik.values.urgency}
                    onChange={(e, value) => formik.setFieldValue('urgency', value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Stack spacing={1}>
                  <InputLabel>Not</InputLabel>
                  <TextField
                    id="note"
                    name="note"
                    multiline
                    placeholder="Ürünle ilgili ek bilgi verebilirsiniz."
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                    fullWidth
                    type="text"
                  />
                </Stack>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button data-cy={'add-product-submit'} variant="contained" type="submit">
                  <FormattedMessage id={rowToUpdate != undefined ? 'update' : 'add-product'} />
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

AddProduct.propTypes = {
  handleNext: PropTypes.func,
  setErrorIndex: PropTypes.func
};

export default AddProduct;
