import { useMutation } from 'react-query';
import axios from 'utils/axios2';

const reportError = async (error) => {
  const { data } = await axios.post('/api/errors/report', error);
  return data;
};
export const useReportError = () => {
  return useMutation(reportError, {
    onError: (error) => {
      console.error('Error reporting failed:', error);
    },
    onSuccess: (data) => {
      console.log('Error reported successfully:', data);
    }
  });
};
