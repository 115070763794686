import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, FormLabel, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';
import { useAddProduct, useUpdateProduct, useUploadProductImage } from 'hooks/products/useProductsData';
import { ThemeMode } from 'config';
import Avatar from 'components/@extended/Avatar';
import CircularWithLabel from 'components/@extended/progress/CircularWithLabel';
import Loader from 'components/Loader';
import ProductTypeSelect from 'components/select-boxes/ProductTypeSelect';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Camera } from 'iconsax-react';
import ProductNameSelect from 'components/select-boxes/ProductNameSelect';

const validationSchema = yup.object({
  name: yup.string().required('Ürün ismi gerekli'),
  unit: yup.string().required('Ölçü birimi gerekli'),
  type: yup.string().required('Ürün tipi gerekli')
});

// ==============================|| FORM VALIDATION - INSTANT FEEDBACK  ||============================== //

const ProductCreationForm = ({ itemToUpdate, newName, setSelectedProduct, setCreationVisible, distinctValues, setIsChanged }) => {
  const theme = useTheme();

  const { mutate: addProduct, data: addResponse } = useAddProduct();
  const { mutate: updateProduct, data: updateResponse } = useUpdateProduct();
  const { mutate: uploadProductImage, isLoading: isUploadingImage, data: uploadImageData } = useUploadProductImage();

  const formik = useFormik({
    initialValues: {
      name: itemToUpdate?.name || newName || '',
      unit: itemToUpdate?.unit || '',
      type: itemToUpdate?.type || ''
    },
    validationSchema,
    onSubmit: () => {
      console.log('formik.values', formik.values);
      const payload = {
        name: formik.values.name,
        unit: formik.values.unit,
        type: formik.values.type
      };
      if (itemToUpdate != undefined) {
        updateProduct({ id: itemToUpdate.id, ...payload });
      } else {
        addProduct(payload);
      }
    }
  });

  console.log('formik.values', formik.values);

  useEffect(() => {
    if (addResponse?.data?.success) {
      setIsChanged(false);
      setCreationVisible && setCreationVisible(false);
      setSelectedProduct && setSelectedProduct(addResponse?.data?.insertedProduct);
    }
  }, [addResponse?.data]);

  useEffect(() => {
    if (updateResponse?.data?.success) {
      setIsChanged(false);
      setCreationVisible && setCreationVisible(false);
    }
  }, [updateResponse?.data]);

  useEffect(() => {
    if (uploadImageData?.data?.success) {
      setIsChanged(false);
      setCreationVisible && setCreationVisible(false);
    }
  }, [uploadImageData?.data]);

  useEffect(() => {
    setIsChanged(formik.dirty);
  }, [formik.dirty, setIsChanged]);

  const [selectedImage, setSelectedImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const onProgress = (percentCompleted) => {
    setProgress(percentCompleted);
  };
  useEffect(() => {
    if (selectedImage) {
      uploadProductImage({ body: { image: selectedImage, product_id: itemToUpdate?.id }, onProgress });
    }
  }, [selectedImage]);

  const product_image = process.env.REACT_APP_API_URL + itemToUpdate?.image_url || null;

  return (
    <MainCard
      title={
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ mt: 1 }}>
            {itemToUpdate != undefined ? 'Ürün Güncelleme' : 'Yeni Ürün Ekleme'}
          </Typography>
        </Stack>
      }
    >
      {isUploadingImage && <Loader />}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {itemToUpdate && (
            <Grid item xs={12}>
              <Stack spacing={2.5} alignItems="center" sx={{ m: 3 }}>
                <FormLabel
                  htmlFor="change-product-image"
                  sx={{
                    position: 'relative',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    '&:hover .MuiBox-root': { opacity: 1 },
                    cursor: 'pointer'
                  }}
                >
                  <Avatar alt="Product Image" src={product_image} sx={{ width: 200, height: 200 }} />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .55)' : 'rgba(0,0,0,.45)',
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Stack spacing={0.5} alignItems="center">
                      <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '1.5rem' }} />
                      <Typography sx={{ color: 'secondary.lighter' }} variant="h6">
                        {product_image != null ? 'Ürün Resmini Değiştir' : 'Ürün Resmi Yükle'}
                      </Typography>
                    </Stack>
                  </Box>
                  {progress > 0 && progress < 100 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.25)',
                        width: '100%',
                        height: '100%',
                        opacity: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularWithLabel size={72} variant="determinate" value={progress} color="success" />
                    </Box>
                  )}
                </FormLabel>
                <TextField
                  type="file"
                  id="change-product-image"
                  placeholder="Outlined"
                  variant="outlined"
                  sx={{ display: 'none' }}
                  onChange={(e) => setSelectedImage(e.target.files?.[0])}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel>
                <FormattedMessage id="name" />
              </InputLabel>
              {/** TODO: Autocomplete componentinde error mesajı göstermenin ilk örneği. Diğeryerlere de uygulanabilir */}
              <ProductNameSelect
                id="name"
                formik={formik}
                setCreationVisible={setCreationVisible}
                selectedProductName={formik.values.name}
                onChange={(value) => {
                  console.log('onChange product name', value);
                  formik.setFieldValue('name', value);
                }}
                setSelectedProductName={(value) => {
                  console.log('_______________value', value);
                  formik.setFieldValue('name', value);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel>
                <FormattedMessage id="unit" />
              </InputLabel>
              {/** TODO: Autocomplete componentinde error mesajı göstermenin ilk örneği. Diğeryerlere de uygulanabilir */}
              <Autocomplete
                options={distinctValues?.unit || []}
                getOptionLabel={(option) => option || ''}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.unit && Boolean(formik.errors.unit)}
                    helperText={formik.touched.unit && formik.errors.unit}
                  />
                )}
                onInputChange={(event, value, reason) => {
                  // Update Formik field value on input change
                  if (reason === 'input') {
                    formik.setFieldValue('unit', value);
                  }
                }}
                onChange={(event, value, reason) => {
                  if (reason === 'selectOption') {
                    formik.setFieldValue('unit', value?.inputValue || value);
                    formik.setFieldTouched('unit', true, false); // Update touch without triggering validation
                  } else if (reason === 'clear') {
                    formik.setFieldValue('unit', '');
                    formik.setFieldTouched('unit', true);
                  }
                }}
                onBlur={() => {
                  // Ensure field is touched and trigger validation on blur
                  formik.setFieldTouched('unit', true);
                  formik.validateField('unit');
                }}
                value={formik.values.unit}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel>
                <FormattedMessage id="type" />
              </InputLabel>
              <ProductTypeSelect
                id="type"
                formik={formik}
                selectedProductType={formik.values.type}
                setSelectedProductType={(value) => formik.setFieldValue('type', value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button variant="contained" type="submit" color="primary">
                  <FormattedMessage id={itemToUpdate != undefined ? 'update' : 'add'} />
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default ProductCreationForm;
