import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Typography,
  InputAdornment,
  Chip,
  Button,
  Select,
  MenuItem,
  FormControl,
  List,
  Link,
  ListItemText,
  Dialog,
  Stack,
  ListItem,
  Divider,
  ListItemIcon
} from '@mui/material';
import { getColorByRank, NumberFormatCustom } from 'utils/utkuFunctions';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTooltip from 'components/@extended/Tooltip';
import { useEffect } from 'react';
import MainCard from 'components/MainCard';
import { CurrencyChip, currencySymbol } from 'utils/currencyFunction';
import DateSelect from 'components/select-boxes/DateSelect';
import { PopupTransition } from 'components/@extended/Transitions';
import { ArrowDown2, Box2, Calendar, Rank } from 'iconsax-react';
/* import IsCheapest from 'components/data/IsCheapestChip'; */
/* import IsFastest from 'components/data/IsFastest'; */
import { useExchangeRatesToday } from 'hooks/exchangeRates/useExchangeRatesData';
import Loader from 'components/Loader';
import OfferedProductPricesChart from 'sections/charts/apexchart/OfferedProductPricesChart';
import OfferedProductAmountsChart from 'sections/charts/apexchart/OfferedProductAmountsChart';
import { FormattedDate, FormattedNumber } from 'react-intl';
import ExportOptions from 'components/third-party/ReactTable';

const getValidationSchema = (index) => {
  return yup.object({
    [`offered_amount-${index}`]: yup
      .number()
      .min(0)
      .required('Bu ürünü temin edemeyecekseniz miktarı "0" yapabilirsiniz.')
      .test('amountRange', `Teklif edilen miktar talep edilen miktarın %200'ünden fazla olamaz`, function (value) {
        const requestedAmount = this.resolve(yup.ref(`amount-${index}`));
        /*  if (value < requestedAmount * 0.0999999 && value !== 0) {
          return this.createError({
            message: `Teklif edilen miktar, talep edilen miktarın %10'undan az olamaz (en az: ${(requestedAmount * 0.1).toFixed(2)})`
          });
        } */
        if (value > requestedAmount * 2) {
          return this.createError({
            message: `Teklif edilen miktar, %200'ünden fazla olamaz (en fazla: ${(requestedAmount * 2).toFixed(2)})`
          });
        }
        return true;
      }),
    [`termin_date-${index}`]: yup.date().nullable(),
    [`offered_brand-${index}`]: yup
      .string()
      .max(56, 'Marka 56 karakterden uzun olamaz')
      .test('required if brand is not null and offered_amount is not 0', function (value) {
        const brand = this.resolve(yup.ref(`brand-${index}`));
        const offeredAmount = this.resolve(yup.ref(`offered_amount-${index}`));
        if (brand && offeredAmount != 0 && !value) {
          return this.createError({ message: 'Marka gereklidir' });
        }

        return true;
      }),

    [`offered_model-${index}`]: yup
      .string()
      .max(56, 'Model 56 karakterden uzun olamaz')
      .test('required if model is not null and offered_amount is not 0', function (value) {
        const model = this.resolve(yup.ref(`model-${index}`));
        const offeredAmount = this.resolve(yup.ref(`offered_amount-${index}`));
        if (model && offeredAmount != 0 && !value) {
          return this.createError({ message: 'Model gereklidir' });
        }
        return true;
      }),
    [`price-${index}`]: yup
      .number()
      .required('Birim Fiyat gereklidir')
      .min(0, 'Birim fiyat 0 dan büyük olmalıdır')
      .test('maxPrice', function (value) {
        if (value > 1000000) {
          return this.createError({ message: 'Birim fiyat 1.000.000 den büyük olamaz' });
        }
        return true;
      }),
    [`currency_code-${index}`]: yup.string().required('Para birimi gereklidir').oneOf(['TRY', 'USD', 'EUR'], 'Geçersiz para birimi'),
    [`discount-${index}`]: yup
      .number()
      .required('İskonto gereklidir')
      .min(0, 'İskonto 0 dan büyük olmalıdır')
      .test('maxDiscount', function (value) {
        const discountType = this.resolve(yup.ref(`discount_type-${index}`));
        const price = this.resolve(yup.ref(`price-${index}`));
        const amount = this.resolve(yup.ref(`amount-${index}`));
        const currencyCode = this.resolve(yup.ref(`currency_code-${index}`));
        if (discountType === 'P' && value > 100) {
          return this.createError({ message: 'İskonto 100% den büyük olamaz' });
        } else if (discountType === 'V' && value > price * amount) {
          return this.createError({
            message: `İskonto toplam fiyattan (${currencySymbol(currencyCode)}${(price * amount).toFixed(2)}) büyük olamaz`
          });
        }
        return true;
      }),
    [`discount_type-${index}`]: yup.string().required('İskonto tipi gereklidir'),
    [`vat-${index}`]: yup.number().min(0, 'KDV en az %0 olabilir').max(100, 'KDV en fazla %100 olabilir').required('KDV gereklidir'),
    [`tevkifat-${index}`]: yup
      .number()
      .required('Tevkifat gereklidir')
      .min(0, "Tevkifat 0'dan küçük olamaz")
      .max(100, "Tevkifat 100'den büyük olamaz")
  });
};

// Function to get the combined validation schema for all rows
const getCombinedValidationSchema = (data) => {
  const schemaFields = data.reduce((acc, row, index) => {
    const validationSchema = getValidationSchema(index);
    return { ...acc, ...validationSchema.fields };
  }, {});

  return yup.object().shape(schemaFields);
};

const OfferedProductsTable = ({ data, setOfferedProducts, showComparisons = false, setFormikErrors, readOnly }) => {
  const theme = useTheme();
  const { data: exchangeRatesData, isLoading: exhangesLoading } = useExchangeRatesToday();
  const exchangeRates = exchangeRatesData?.data;
  const [compareIndex, setCompareIndex] = useState(0);
  const [comparisonDialogOpen, setComparisonDialogOpen] = useState(false);
  const mergedData = /* mergeDataByProductId(data); */ data;
  const isFromQuota = data.some((item) => item.quota_info != null);
  console.log('(non)mergedData', mergedData);

  const formik = useFormik({
    initialValues: mergedData.reduce((acc, row, index) => {
      // if termin date not exist put 7 day later Fday
      acc[`termin_date-${index}`] = row?.termin_date || new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      acc[`amount-${index}`] = row?.amount || '';
      acc[`offered_amount-${index}`] = row?.offered_amount || '0';
      acc[`brand-${index}`] = row?.brand || row?.quota_info?.brand || '';
      acc[`offered_brand-${index}`] = row?.offered_brand || '';
      acc[`model-${index}`] = row?.model || row?.quota_info?.model || '';
      acc[`offered_model-${index}`] = row?.offered_model || '';
      acc[`price-${index}`] = row?.price || row?.quota_info?.price || '0';
      acc[`currency_code-${index}`] = row?.currency_code || row?.quota_info?.currency_code || 'TRY';
      acc[`discount-${index}`] = row?.discount || row?.quota_info?.discount || '0';
      acc[`discount_type-${index}`] = row?.discount_type || row?.quota_info?.discount_type || 'P';
      acc[`vat-${index}`] = row?.vat || row?.quota_info?.vat || 20;
      acc[`tevkifat-${index}`] = row?.tevkifat || row?.quota_info?.tevkifat || '0';
      acc[`merged_from-${index}`] = row?.merged_from || [];
      return acc;
    }, {}),
    enableReinitialize: true,

    validationSchema: getCombinedValidationSchema(mergedData),
    onSubmit: (values) => {
      // Handle form submission
      console.log('values', values);
    }
  });

  console.log('formik.values.offered_amount-0', formik.values['offered_amount-0']);

  const validateAll = () => {
    if (readOnly) return;
    formik.validateForm().then((errors) => {
      console.log('errors', errors);
      formik.setTouched(
        Object.keys(errors).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    });
  };

  useEffect(() => {
    validateAll();
    console.log('formik.errors', formik.errors);
    setFormikErrors && setFormikErrors(formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    // Map formik values back to the original data structure
    const updatedProducts = data.map((row, index) => {
      return {
        ...row,
        req_prod_id: row.req_prod_id ?? row.id,
        termin_date: new Date(formik.values[`termin_date-${index}`])?.toISOString()?.split('T')?.[0] || null,
        offered_amount: formik.values[`offered_amount-${index}`] || '0',
        brand: formik.values[`brand-${index}`],
        offered_brand: formik.values[`offered_brand-${index}`],
        model: formik.values[`model-${index}`],
        offered_model: formik.values[`offered_model-${index}`],
        price: formik.values[`price-${index}`] || '0',
        currency_code: formik.values[`currency_code-${index}`],
        discount: formik.values[`discount-${index}`],
        discount_type: formik.values[`discount_type-${index}`],
        vat: formik.values[`vat-${index}`],
        tevkifat: formik.values[`tevkifat-${index}`],
        total_price: formik.values[`price-${index}`] * formik.values[`offered_amount-${index}`]
      };
    });

    !readOnly && setOfferedProducts && setOfferedProducts(updatedProducts);
  }, [formik.values]);
  if (exhangesLoading) {
    return <Loader />;
  }

  // check if offered_amount is 0 or not from formik values offered_amount
  const showCell = (index) => {
    return parseFloat(formik.values[`offered_amount-${index}`]) != 0.0;
  };

  console.log('data', data);

  const chart = data[compareIndex]?.chart;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog
        maxWidth="lg"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          setComparisonDialogOpen(false);
        }}
        scroll="body"
        open={comparisonDialogOpen}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        {data[compareIndex]?.sortedByPrice && (
          <MainCard title="Ürün Kalemi Kıyaslama">
            <Stack direction={'row'} justifyContent={'space-between'}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h3' }}
                primary={`${data[compareIndex]?.product?.name}`}
                secondaryTypographyProps={{ variant: 'h6' }}
                secondary={`${data[compareIndex]?.product?.unit}`}
              />
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', textAlign: 'right' }}
                primary={
                  <Link href={`/buy-requests/${data[compareIndex]?.buy_request_id}`}>
                    Satınalım Talebi #{data[compareIndex]?.buy_request_id}
                  </Link>
                }
                secondaryTypographyProps={{ variant: 'h6', textAlign: 'right' }}
                secondary={`Ürün Talebi #${data[compareIndex]?.req_prod_id}`}
              />
            </Stack>
            <Divider sx={{ my: 2 }} />

            <div id="chart-price-comparison">
              <OfferedProductPricesChart chart={chart?.price} />
            </div>
            <div id="chart-amount-comparison">
              <OfferedProductAmountsChart chart={chart?.amount} />
            </div>

            <Divider sx={{ my: 2 }} />
            <Stack mt={3} direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h6">Termin Tarihleri</Typography>
              <ArrowDown2 />
            </Stack>

            <List sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {data[compareIndex]?.sortedByTime?.map((item) => (
                <ListItem key={item.id} sx={{ width: '100%' }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 64,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Calendar color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link href={`/offers/${item.offer_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {`Teklif #${item.offer_id} | ${item.supplier.name}`}
                      </Link>
                    }
                    secondary={<FormattedDate value={item.termin_date} year="numeric" month="2-digit" day="2-digit" />}
                  />
                </ListItem>
              ))}
            </List>
          </MainCard>
        )}
      </Dialog>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/*  <TableCell align="left"></TableCell> */}
                  <TableCell sx={{ minWidth: readOnly ? 120 : 200 }} align="left">
                    Talep Edilen Ürün
                  </TableCell>
                  {isFromQuota && (
                    <TableCell sx={{ minWidth: readOnly ? 'fit-content' : 160 }} align="left">
                      Ürün Bağlantısı
                    </TableCell>
                  )}

                  <TableCell sx={{ minWidth: readOnly ? 'fit-content' : 160 }} align="left">
                    Teklif Miktarı
                  </TableCell>
                  {/* <TableCell sx={{ width: 60 }} align="left">
                    Marka
                  </TableCell>
                  <TableCell sx={{ width: 60 }} align="left">
                    Model
                  </TableCell>
                  <TableCell sx={{ width: 60 }} align="left">
                    Miktar
                  </TableCell> */}

                  <TableCell sx={{ minWidth: readOnly ? 'fit-content' : 120 }} align="left">
                    Marka
                  </TableCell>

                  <TableCell sx={{ minWidth: readOnly ? 'fit-content' : 120 }} align="left">
                    Model
                  </TableCell>

                  <TableCell sx={{ minWidth: readOnly ? 'min-content' : 160 }}>Termin Tarihi</TableCell>

                  <TableCell sx={{ minWidth: readOnly ? 'min-content' : 180 }} align="left">
                    {isFromQuota ? 'Anlaşılan Birim Fiyat' : 'BİRİm Fİyat'}
                  </TableCell>
                  <TableCell sx={{ minWidth: readOnly ? 'min-content' : 140 }} align="left">
                    İskonto
                  </TableCell>
                  <TableCell sx={{ minWidth: readOnly ? 'min-content' : 90 }} align="left">
                    KDV
                  </TableCell>
                  <TableCell sx={{ minWidth: readOnly ? 'min-content' : 90 }} align="left">
                    TEVKİFAT
                  </TableCell>
                  <TableCell sx={{ minWidth: 180 }} align="right">
                    Toplam Fiyat
                  </TableCell>
                  {
                    // Show the comparison columns if the showComparisons prop is true
                    showComparisons && (
                      <TableCell sx={{ minWidth: 100 }} align="right">
                        Kıyas
                      </TableCell>
                    )
                  }
                  {
                    <TableCell align="right">
                      <ExportOptions
                        text=""
                        data={data}
                        filename="teklif-ürünleri"
                        hiddenColumns={[]}
                        columns={[
                          {
                            Header: 'Talep Edilen Ürün',
                            accessor: 'product.name'
                          },
                          {
                            Header: 'Talep Edilen Miktar',
                            accessor: 'amount'
                          },
                          {
                            Header: 'Teklif Edilen Miktar',
                            accessor: 'offered_amount'
                          },
                          {
                            Header: 'Birim',
                            accessor: 'product.unit'
                          },

                          {
                            Header: 'Marka',
                            accessor: 'offered_brand'
                          },
                          {
                            Header: 'Model',
                            accessor: 'offered_model'
                          },
                          {
                            Header: 'Termin Tarihi',
                            accessor: 'termin_date',
                            Cell: ({ value }) => <FormattedDate value={value} year="numeric" month="2-digit" day="2-digit" />
                          },
                          {
                            Header: 'Para Birimi',
                            accessor: 'currency_code'
                          },
                          {
                            Header: 'Birim Fiyat',
                            accessor: 'price',
                            Cell: ({ value, row }) => (
                              <FormattedNumber value={value?.toFixed(2)} style="currency" currency={row.original.currency_code} />
                            )
                          },
                          {
                            Header: 'İskonto',
                            accessor: 'discount',
                            Cell: ({ value, row }) =>
                              row.original.discount_type === 'P' ? `${value}%` : currencySymbol(row.original.currency_code) + value
                          },
                          {
                            Header: 'KDV',
                            accessor: 'vat',
                            Cell: ({ value }) => `${value}%`
                          },
                          {
                            Header: 'TEVKİFAT',
                            accessor: 'tevkifat',
                            Cell: ({ value }) => `${value}%`
                          },
                          {
                            Header: 'Toplam Fiyat',
                            accessor: 'total_price',
                            Cell: ({ value, row }) => (
                              <FormattedNumber value={value?.toFixed(2)} style="currency" currency={row.original.currency_code} />
                            )
                          }
                        ]}
                      />
                    </TableCell>
                  }

                  {/* <TableCell sx={{ minWidth: 200 }} align="right">
                    Toplam İskonto
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }} align="right">
                    Toplam KDV
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {mergedData.map((row, index) => {
                  return (
                    <>
                      <TableRow data-cy={`offered-product-row-${index}`} hover key={row.id}>
                        {/*  <TableCell align="left" sx={{ p: 0.5 }}>
                          {row?.merged_from?.length}
                        </TableCell> */}

                        <TableCell data-cy={`offered-product-details`} align="left" sx={{ p: 0.5 }}>
                          <Typography data-cy={`offered-product-name`} variant="body1" sx={{ fontWeight: 600 }}>
                            {row?.product?.name}
                          </Typography>
                          <ListItemText
                            primary={
                              <Typography>
                                {row?.amount} {row?.product?.unit}
                              </Typography>
                            }
                            secondary={
                              <Stack>
                                <Typography>{row?.brand}</Typography>
                                <Typography>{row?.model}</Typography>
                              </Stack>
                            }
                            sx={{ fontSize: 12, fontWeight: 400 }}
                          />
                        </TableCell>

                        {row?.quota_info && (
                          <TableCell align="left" sx={{ p: 0.5 }}>
                            <Stack spacing={1}>
                              <Box2 color={theme.palette.success.main} />
                              <Stack direction="row" spacing={0.4} alignItems="center">
                                <Typography component={Link} to={`/quotations/${row?.quota_info?.quotation_id}`}>
                                  Ürün Bağlantısı #{row?.quota_info?.quotation_id}
                                </Typography>
                              </Stack>
                              <ListItemText
                                primary={`${parseFloat(
                                  parseFloat(row?.quota_info?.used_amount) + parseFloat(formik.values[`offered_amount-${index}`] || 0)
                                )}/${parseFloat(row?.quota_info?.quotation_amount)}`}
                                secondary={'Kullanılan Miktar'}
                              />
                            </Stack>
                          </TableCell>
                        )}

                        {/* <TableCell align="right">{row?.product?.type}</TableCell> */}
                        {/*  <TableCell align="right">{row.amount}</TableCell> */}
                        <TableCell align="left">
                          {readOnly ? (
                            <Typography id={`offered_amount-${index}`}>
                              {parseFloat(formik.values[`offered_amount-${index}`])?.toLocaleString('tr-TR')}
                            </Typography>
                          ) : (
                            <TextField
                              id={`offered_amount-${index}`}
                              name={`offered_amount-${index}`}
                              value={formik.values[`offered_amount-${index}`]}
                              label={`Talep: ${parseFloat(formik.values[`amount-${index}`])?.toLocaleString('tr-TR')}`}
                              placeholder={`Talep: ${parseFloat(formik.values[`amount-${index}`])?.toLocaleString('tr-TR')}`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched[`offered_amount-${index}`] && Boolean(formik.errors[`offered_amount-${index}`])}
                              helperText={formik.touched[`offered_amount-${index}`] && formik.errors[`offered_amount-${index}`]}
                              inputProps={{ readOnly }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment>{row?.product?.unit}</InputAdornment>
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`offered_brand-${index}`}>{formik.values[`offered_brand-${index}`]}</Typography>
                          ) : (
                            showCell(index) && (
                              <TextField
                                id={`offered_brand-${index}`}
                                name={`offered_brand-${index}`}
                                multiline
                                label={formik.values[`brand-${index}`] != '' ? `Talep: ${formik.values[`brand-${index}`]}` : '' || ''}
                                placeholder={
                                  formik.values[`brand-${index}`] != '' ? `Talep: ${formik.values[`brand-${index}`]}` : '' || 'Marka'
                                }
                                value={formik.values[`offered_brand-${index}`]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[`offered_brand-${index}`] && Boolean(formik.errors[`offered_brand-${index}`])}
                                helperText={formik.touched[`offered_brand-${index}`] && formik.errors[`offered_brand-${index}`]}
                                inputProps={{ readOnly }}
                              />
                            )
                          )}
                        </TableCell>

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`offered_model-${index}`}>{formik.values[`offered_model-${index}`]}</Typography>
                          ) : (
                            showCell(index) && (
                              <TextField
                                id={`offered_model-${index}`}
                                name={`offered_model-${index}`}
                                multiline
                                label={formik.values[`model-${index}`] != '' ? `Talep: ${formik.values[`model-${index}`]}` : '' || ''}
                                placeholder={
                                  formik.values[`model-${index}`] != '' ? `Talep: ${formik.values[`model-${index}`]}` : '' || 'Model'
                                }
                                value={formik.values[`offered_model-${index}`]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[`offered_model-${index}`] && Boolean(formik.errors[`offered_model-${index}`])}
                                helperText={formik.touched[`offered_model-${index}`] && formik.errors[`offered_model-${index}`]}
                                inputProps={{ readOnly }}
                              />
                            )
                          )}
                        </TableCell>

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`termin_date-${index}`}>
                              <FormattedDate value={formik.values[`termin_date-${index}`]} year="numeric" month="2-digit" day="2-digit" />
                            </Typography>
                          ) : (
                            showCell(index) && (
                              <DateSelect
                                disablePast
                                disabled={readOnly}
                                name={`termin_date-${index}`}
                                value={formik.values[`termin_date-${index}`]}
                                onChange={(date) => formik.setFieldValue(`termin_date-${index}`, date)}
                                inputProps={{ readOnly }}
                              />
                            )
                          )}
                        </TableCell>

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`price-${index}`}>
                              {currencySymbol(formik.values[`currency_code-${index}`])}
                              {parseFloat(formik.values[`price-${index}`])?.toLocaleString('tr-TR', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </Typography>
                          ) : (
                            showCell(index) && (
                              <TextField
                                id={`price-${index}`}
                                name={`price-${index}`}
                                value={formik.values[`price-${index}`]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[`price-${index}`] && Boolean(formik.errors[`price-${index}`])}
                                helperText={formik.touched[`price-${index}`] && formik.errors[`price-${index}`]}
                                inputProps={{ readOnly: readOnly || isFromQuota }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  startAdornment: (
                                    <InputAdornment position="start" sx={{ p: 0, m: 0 }}>
                                      <FormControl variant="standard">
                                        <Select
                                          aria-label="Select currency"
                                          value={formik.values[`currency_code-${index}`] || 'TRY'}
                                          onChange={(e) => formik.setFieldValue(`currency_code-${index}`, e.target.value)}
                                          displayEmpty
                                          disableUnderline
                                          inputProps={{ 'aria-label': 'Select currency', readOnly: readOnly || isFromQuota }}
                                          renderValue={(value) => CurrencyChip(value)}
                                          sx={{ minWidth: 20 }}
                                        >
                                          <MenuItem value="" disabled>
                                            <em>Para Birimi</em>
                                          </MenuItem>
                                          <MenuItem value="TRY">
                                            <ListItemText primary="₺" secondary="TRY" />
                                          </MenuItem>
                                          <MenuItem value="USD">
                                            <ListItemText
                                              primary="$"
                                              secondary={`USD (${('' + exchangeRates?.USD?.buy_price || '1')?.toLocaleString('tr-TR', {
                                                style: 'currency',
                                                currency: 'TRY'
                                              })})`}
                                            />
                                          </MenuItem>
                                          <MenuItem value="EUR">
                                            <ListItemText
                                              primary="€"
                                              secondary={`EUR (${('' + exchangeRates?.EUR?.buy_price || '1')?.toLocaleString('tr-TR', {
                                                style: 'currency',
                                                currency: 'TRY'
                                              })})`}
                                            />
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`discount-${index}`}>
                              {formik.values[`discount_type-${index}]`] == 'P'
                                ? `${formik.values[`discount-${index}`]}%`
                                : `${currencySymbol(formik.values[`currency_code-${index}`])}${formik.values[`discount-${index}`]}`}
                            </Typography>
                          ) : (
                            showCell(index) && (
                              <TextField
                                id={`discount-${index}`}
                                name={`discount-${index}`}
                                value={formik.values[`discount-${index}`]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched[`discount-${index}`] && Boolean(formik.errors[`discount-${index}`])}
                                helperText={formik.touched[`discount-${index}`] && formik.errors[`discount-${index}`]}
                                inputProps={{ readOnly }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  endAdornment: (
                                    <InputAdornment>
                                      <CustomTooltip
                                        color={formik.values[`discount_type-${index}`] === 'P' ? 'primary' : 'success'}
                                        title="Yüzdelik (%) veya Değer ile giriş yapabilirsiniz"
                                        arrow
                                      >
                                        <Chip
                                          variant="outlined"
                                          label={
                                            formik.values[`discount_type-${index}`] === 'P'
                                              ? '%'
                                              : currencySymbol(formik.values[`currency_code-${index}`])
                                          }
                                          color={formik.values[`discount_type-${index}`] === 'P' ? 'primary' : 'success'}
                                          onClick={() => {
                                            if (!readOnly && !isFromQuota) {
                                              formik.setFieldValue(
                                                `discount_type-${index}`,
                                                formik.values[`discount_type-${index}`] === 'P' ? 'V' : 'P'
                                              );
                                            }
                                          }}
                                        />
                                      </CustomTooltip>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )
                          )}
                        </TableCell>

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`vat-${index}`}>{formik.values[`vat-${index}`]}%</Typography>
                          ) : (
                            showCell(index) && (
                              <TextField
                                id={`vat-${index}`}
                                name={`vat-${index}`}
                                value={formik.values[`vat-${index}`]}
                                onChange={formik.handleChange}
                                inputProps={{ readOnly, prefix: '%' }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                  /*  endAdornment: <InputAdornment>%</InputAdornment> */
                                }}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched[`vat-${index}`] && formik.errors[`vat-${index}`]}
                                error={formik.touched[`vat-${index}`] && Boolean(formik.errors[`vat-${index}`])}
                              />
                            )
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly && showCell(index) ? (
                            <Typography id={`tevkifat-${index}`}>{formik.values[`tevkifat-${index}`]}%</Typography>
                          ) : (
                            showCell(index) && (
                              <TextField
                                id={`tevkifat-${index}`}
                                name={`tevkifat-${index}`}
                                value={formik.values[`tevkifat-${index}`]}
                                onChange={formik.handleChange}
                                inputProps={{ readOnly, prefix: '%' }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                  /* endAdornment: <InputAdornment>%</InputAdornment> */
                                }}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched[`tevkifat-${index}`] && formik.errors[`tevkifat-${index}`]}
                                error={formik.touched[`tevkifat-${index}`] && Boolean(formik.errors[`tevkifat-${index}`])}
                              />
                            )
                          )}
                        </TableCell>
                        {showCell(index) ? (
                          <TableCell align="right" sx={{ p: 0.5 }}>
                            {formik.values[`currency_code-${index}`] === 'TRY' ? (
                              // If the currency is TRY, just show the TRY value
                              <TextField
                                id={`total-price-${index}`}
                                value={formik.values[`price-${index}`] * formik.values[`offered_amount-${index}`]}
                                inputProps={{ readOnly: true }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  startAdornment: <InputAdornment position="start">₺</InputAdornment>
                                }}
                                variant="standard"
                                sx={{ mt: 1 }}
                              />
                            ) : (
                              <TextField
                                id={`total-price-${index}`}
                                value={
                                  `≈ ${currencySymbol('TRY')}` +
                                  (
                                    formik.values[`price-${index}`] *
                                    row.amount *
                                    parseFloat(exchangeRates[formik.values[`currency_code-${index}`]]?.buy_price)
                                  ).toFixed(2)
                                }
                                inputProps={{ readOnly: true }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencySymbol(formik.values[`currency_code-${index}`])}
                                      {parseFloat(formik.values[`price-${index}`] * row.amount).toFixed(2)}
                                    </InputAdornment>
                                  ),
                                  readOnly: true
                                }}
                                variant="standard"
                                sx={{ mt: 1 }}
                              />
                            )}
                          </TableCell>
                        ) : (
                          <TableCell align="right" sx={{ p: 0.5 }}></TableCell>
                        )}
                        {showComparisons && (
                          <TableCell align="right">
                            <Button
                              data-cy={`price-rank-compare-${index}`}
                              color={
                                mergedData[index]?.price_rank == 0
                                  ? 'error'
                                  : getColorByRank(mergedData[index]?.price_rank, mergedData[index]?.sortedByPrice?.length)
                              }
                              startIcon={<Rank />}
                              onClick={() => {
                                setCompareIndex(index);
                                setComparisonDialogOpen(true);
                              }}
                            >
                              {`${mergedData[index]?.price_rank == 0 ? '?' : mergedData[index]?.price_rank}/${
                                mergedData[index]?.sortedByPrice?.length
                              }`}
                            </Button>
                          </TableCell>
                        )}
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {1 === '2' && <Button onClick={() => validateAll()}>Validate</Button>}
    </form>
  );
};

export default OfferedProductsTable;
