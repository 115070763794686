import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// fonts
import 'assets/fonts/inter/inter.css';

// scroll bar
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// project-imports
import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import { JoyrideProvider } from 'contexts/JoyrideContext';

const container = document.getElementById('root');
const root = createRoot(container);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <div translate="no">
    <ReduxProvider store={store}>
      <ConfigProvider>
        <JoyrideProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </JoyrideProvider>
      </ConfigProvider>
    </ReduxProvider>
  </div>
);

reportWebVitals();
