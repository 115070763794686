import React from 'react';
import { Typography, Chip, Stack, ListItemText } from '@mui/material';

import { FormattedNumber } from 'react-intl';

/* import { companyColor } from 'components/select-boxes/CompanySelect'; */
import CustomTooltip from 'components/@extended/Tooltip';
import { Copyright } from 'iconsax-react';

const CompanyCodeChip = ({ company }) => {
  return (
    <Chip
      sx={{ flexGrow: 0 }}
      size="sm"
      icon={<Copyright />}
      label={
        <CustomTooltip title={company.name} arrow /* color={companyColor(company?.type)} */>
          <Typography color={'contrastText'}>{company?.code}</Typography>
        </CustomTooltip>
      }
      /*   color={companyColor(company?.type)} */
    />
  );
};
const CompanyCell = ({ company, onlyCode, maxWidth }) => {
  if (!company) return null;
  if (onlyCode) return <CompanyCodeChip company={company} />;
  return (
    <Stack direction="row" alignItems={'center'} columnGap={1} sx={{ maxWidth: maxWidth || null }}>
      <CompanyCodeChip company={company} />
      <Typography noWrap={true}>{company.name}</Typography>
      {company.stats_____ && (
        <Stack direction="row" alignItems={'center'} spacing={4} pl={4}>
          <ListItemText primary={company.stats.total_offers} secondary="Verilen Teklifler" />
          <ListItemText
            primary={
              <FormattedNumber
                value={company.stats.grand_total}
                style="currency"
                currency="TRY"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            }
            secondary="Toplam Hacim"
          />
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyCell;
