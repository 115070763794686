import { useEffect } from 'react';
import { useReportError } from 'hooks/error/useErrorsData';

const ErrorReporter = () => {
  const { mutate: reportError } = useReportError();

  useEffect(() => {
    console.log('ErrorReporter mounted');

    window.onerror = function (message, source, lineno, colno, error) {
      const errorData = {
        message,
        source,
        lineno,
        colno,
        error: error?.stack || error?.message || 'No error stack/message'
      };

      reportError(errorData);
    };

    const handleUnhandledRejection = (event) => {
      const errorData = {
        message: event.reason?.message || 'Unhandled promise rejection',
        stack: event.reason?.stack || 'No stack trace'
      };

      reportError(errorData);
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.onerror = null; // Reset window.onerror to avoid memory leaks
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, [reportError]);

  return null;
};

export default ErrorReporter;
