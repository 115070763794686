export const tableNames = {
  addendums: 'Zeyilname',
  announcements: 'Duyuru',
  blocks: 'Blok',
  checks: 'Çek',
  contracts: 'Sözleşme',
  companies: 'Firma',
  company_contacts: 'Firma Kişisi',
  daily_reports: 'Günlük Rapor',
  documents: 'Belge',
  hakedisler: 'Hakediş',
  hakedis_templates: 'Hakediş Şablonu',
  messages: 'Mesaj',
  notifications: 'Bildirim',
  offers: 'Teklif',
  orders: 'Sipariş',
  personnels: 'Personel',
  projects: 'Proje',
  products: 'Ürün',
  roles: 'Rol',
  stocks: 'Stok',
  storages: 'Depo',
  tags: 'Etiket',
  transactions: 'İşlem',
  users: 'Kullanıcı',
  vaults: 'Kasa',
  buy_requests: 'Satınalma Talebi',
  requested_products: 'Talep Edilen Ürün',
  quotations: 'Ürün Bağlantısı',
  access: 'Erişim Kuralı',
  works: 'İş İlerlemesi',
  intakes: 'Gelen Malzeme Kaydı',

  dr_weather: 'Hava Durumu Kaydı',
  dr_subcontractor: 'Taşeron Bilgisi',
  dr_personnel: 'Personel Bilgisi',
  dr_machine: 'Makine/Ekipman Bilgisi',
  dr_import: 'Gelen Malzeme Kaydı',
  dr_used: 'Kullanılan Malzeme Kaydı'
};
